import { Button, Chip } from "@mui/material"
import { useRouter } from "next/router"
import React from "react"
import { ArrowRight, CheckCircleFill } from "react-bootstrap-icons"
import styled from "styled-components"
import buyImageFirst from "@/public/pages/homepage/buy-house-valencia.jpg"
import buyImageSecond from "@/public/pages/homepage/invest-reservation.png"
import buyImageThird from "@/public/pages/homepage/moon.svg"
import buyImageFourth from "@/public/pages/homepage/line.svg"
import buyImageFifth from "@/public/pages/homepage/ellipse.svg"
import Image from "next/image"
import translate from "@/utils/translation"
import { useSession } from "next-auth/react"

const BuyMobile = () => {
  const router = useRouter()
  const { data: session } = useSession()

  return (
    <Wrapper>
      <div className="container">
        <div className="row m-0">
          <div className="textarea order-2 order-md-1 col-lg-6 mt-md-auto mb-md-auto textarea">
            <p className="mt-4">{translate("homepage_buy_description")}</p>
            <ul>
              <li>
                <CheckCircleFill className="me-2" color="#00a1d1" size={16} />
                {translate("homepage_buy_usp1")}
              </li>
              <li>
                <CheckCircleFill className="me-2" color="#00a1d1" size={16} />
                {translate("homepage_buy_usp2")}
              </li>
              <li>
                <CheckCircleFill className="me-2" color="#00a1d1" size={16} />
                {translate("homepage_buy_usp3")}
              </li>
            </ul>
            <div className=" mt-3 text-center text-md-start">
              <Button
                variant="contained"
                size={"large"}
                className="rounded-pill"
                onClick={() => {
                  session?.profile
                    ? router.push(`/listings/`)
                    : router.push("/auth/register")
                }}
              >
                {translate("homepage_buy_button")}
                <ArrowRight className="ms-1" />
              </Button>
            </div>
          </div>
          <div className="col-12 order-1 order-md-2 col-lg-6 pt-3 pt-5 pt-md-0">
            <h2>
              {translate("homepage_buy_title")}
              <span className="text-orange">
                {" "}
                {translate("homepage_buy_title_highlight")}
              </span>
            </h2>
            <div className="images">
              <Image
                src="/pages/homepage/Jordan-happy.jpg"
                width="300"
                height="300"
                className="first"
                alt="Buy a house in Valencia"
              />
              <Image
                src="/pages/homepage/pepe-pipes-2.jpg"
                width="300"
                height="3000"
                className="second"
                alt="Best real estate in valencia"
              />
              <Image
                src={buyImageThird.src}
                width="50"
                height="50"
                className="third"
                alt="lines"
              />
              <Image
                src={buyImageFourth.src}
                width="100"
                height="100"
                className="fourth"
                alt="quarter moon"
              />
              <Image
                src={buyImageFifth.src}
                width="700"
                height="700"
                className="fifth"
                alt="ellipse"
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default BuyMobile

const Wrapper = styled.div`
  padding: 10rem 0 4rem;
  width: 100%;
  height: 100%;
  background-color: white;

  @media (max-width: 800px) {
    padding: 5rem 0 3rem;
  }

  .images {
    position: relative;

    .first {
      position: absolute;
      top: -30px;
      z-index: 2;
      left: 260px;
      border-radius: 10px;
    }

    .second {
      position: absolute;
      top: 125px;
      left: 20px;
      z-index: 1;
      border-radius: 10px;
    }

    .third {
      position: absolute;
      top: 65px;
      left: 200px;
      z-index: 1;
    }

    .fourth {
      position: absolute;
      top: 230px;
      right: 240px;
      z-index: 1;
    }

    .fifth {
      position: absolute;
      top: -200px;
      left: -50px;
      z-index: 1;
    }
  }

  @media (max-width: 1400px) {
    .images {
      .first {
        position: absolute;
        top: -30px;
        z-index: 2;
        left: 200px;
        border-radius: 10px;
      }

      .second {
        position: absolute;
        top: 125px;
        left: 20px;
        z-index: 1;
        border-radius: 10px;
      }

      .third {
        position: absolute;
        top: 65px;
        left: 140px;
        z-index: 1;
      }

      .fourth {
        position: absolute;
        top: 230px;
        right: 140px;
        z-index: 1;
      }

      .fifth {
        position: absolute;
        top: -200px;
        left: -50px;
        z-index: 1;
      }
    }
  }

  @media (max-width: 1200px) {
    .images {
      .first {
        top: -30px;
        right: -60px;
      }

      .second {
        top: 125px;
        left: -30px;
      }

      .third {
        top: 65px;
        left: 140px;
      }

      .fourth {
        top: 240px;
        right: 110px;
      }

      .fifth {
        top: -200px;
        left: -50px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 4rem 0 0;
    h2 {
      font-size: 1.8rem;
      line-height: 1.25;
    }

    .images {
      margin-left: 3.3rem;

      right: -20px;
      top: 20px;

      .first {
        top: 0px;
        left: 130px;
        width: 200px;
        height: 200px;
      }

      .second {
        top: 125px;
        left: 0px;
        width: 170px;
        height: 200px;
      }

      .third {
        top: 65px;
        left: 70px;
      }

      .fourth {
        top: 200px;
        left: 170px;
      }

      .fifth {
        display: none;
      }

      height: 350px;
    }
  }

  @media (max-width: 500px) {
    padding: 1rem 0 0;

    h2 {
      font-size: 1.6rem;
    }

    .images {
      margin-left: 1.3rem;

      .first {
        top: 0px;
        left: 80px;
        width: 200px;
        height: 200px;
      }

      .second {
        top: 130px;
        left: -45px;
        width: 200px;
        height: 200px;
      }

      .third {
        top: 65px;
        left: 15px;
      }

      .fourth {
        top: 170px;
        left: 150px;
      }

      .fifth {
        display: none;
      }

      height: 350px;
    }
  }

  .usp {
    margin: auto 0;
    p {
      font-size: 1rem;
    }
  }

  .textarea {
    z-index: 999;
    background: white;
    font-size: 0.875rem;
    position: relative;

    ul {
      padding-bottom: 1rem;
      padding-inline-start: 0px;
      list-style-type: none;

      li {
        margin-top: 1rem;
      }

      svg {
        margin-top: -3px;
      }
    }
  }
`
