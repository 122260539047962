import translate from "@/utils/translation"
import { React } from "react"
import styled from "styled-components"
import Question from "./Question"

const Questions = ({ faqs }) => {
  return (
    <Wrapper>
      {faqs?.map((faq, i) => (
        <Question key={i} faq={faq} />
      ))}
      {faqs?.length === 0 ? translate("FAQ_no_questions") : null}
    </Wrapper>
  )
}

export default Questions

const Wrapper = styled.div`
  margin-bottom: 1rem;
  padding: 0;

  .accordion-button {
    padding-left: 0;
    padding-right: 0;
  }

  .accordion {
    :first-child {
      border-top: 0px;
    }

    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .accordion-header {
    padding-left: 0;
    padding-right: 0;
  }

  .accordion-button {
    box-shadow: none;
  }

  .accordion-body {
    padding-left: 0;
    padding-right: 1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    background-color: white;
    margin-top: 0.5rem;
  }

  .accordion-item {
    :first-child {
      border-top: 0px;
    }
    background-color: white;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .accordion-button {
    background-color: #fbfaf8;

    :not(.collapsed) {
      background-color: #fbfaf8;
      color: #17233b;
    }
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  .translate p {
    font-size: 0.7rem;
    font-weight: 400;

    color: #8a8a8a;

    span {
      text-decoration: underline;

      cursor: pointer;
    }

    svg {
      margin-top: -0.2rem;
    }
  }

  .text-body {
    font-size: 0.875rem;

    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 300;

    ol {
      margin: 0;
      padding: 0rem 0.75rem;
    }

    li {
      margin: 0.5rem 0.5rem;
    }

    ul {
      padding-left: 0.7rem;
    }
  }
`
