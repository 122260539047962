import translate from "@/utils/translation"
import { Avatar, Button } from "@mui/material"
import React from "react"
import { StarFill } from "react-bootstrap-icons"
import styled from "styled-components"

const Modal = ({ modal, setModal }) => {
  return (
    <>
      {modal.show && (
        <Overlay onClick={() => setModal(false)}>
          <ModalContainer onClick={e => e.stopPropagation()}>
            <div className="colorbar" />
            <div className="content">
              <div className="avatar">
                <Avatar
                  src={`./pages/homepage/reviews/${modal.review}.png`}
                  alt="Profile Picture"
                  sx={{ width: 80, height: 80 }}
                />
              </div>
              <div className="title mt-4">
                <h5>{translate(`reviews_item_${modal.review}_name`)}</h5>
                <p>{translate("reviews_subtitle_private")}</p>
              </div>
              <div className="d-flex mt-2">
                <StarFill className="ms-1" color="#FFB800" size={14} />
                <StarFill className="ms-1" color="#FFB800" size={14} />
                <StarFill className="ms-1" color="#FFB800" size={14} />
                <StarFill className="ms-1" color="#FFB800" size={14} />
                <StarFill className="ms-1" color="#FFB800" size={14} />
              </div>
              <div className="review mt-4">
                <p>{translate(`reviews_item_${modal.review}_description`)}</p>
              </div>
              <div className="mt-3 ">
                <Button onClick={() => setModal(false)}>Close</Button>
              </div>
            </div>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default Modal

// Styled Components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is on top of everything */
`

const ModalContainer = styled.div`
  background: white;
  width: 90%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1010;

  .colorbar {
    background-color:#FA8500;
    height: 60px;
    border-radius: 5px 5px 0 0;
  }

  .avatar {
    margin-top: -45px;
  }

  .content {
    padding: 1rem;

    h5 {
      margin-bottom: 0.25rem;
      font-size: 1.25rem;
    }
  }

  svg:first-child {
      margin: 0 !important;
    }

  .review {
    
    p {
      font-size: 1rem;
      font-weight: 300;
      color: #585858;
  }

  .footer {
    display: flex;
    justify-content: center;
  }
`
