import Item from "@/components/listings/overview/listingOverview/item/item"
import { Context } from "@/context/state"
import translate from "@/utils/translation"
import { Button, Fade } from "@mui/material"
import axios from "axios"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useState } from "react"
import TagManager from "react-gtm-module"
import styled from "styled-components"

const Highlights = ({ session }) => {
  const router = useRouter()

  //generate state from context
  const { state, dispatch } = useContext(Context)

  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setFadeIn(true)
  }, [])

  const { listings } = state

  useEffect(() => {
    if (listings?.length === 0) {
      const fetchListings = async () => {
        dispatch({ type: "SET_LOADING", payload: true })

        const result = await axios
          .get("/api/listings?split=true&limit=4")
          .catch(err => console.log(err))

        if (result.data.length > 0) {
          const projects = result.data.sort((a, b) => {
            return (
              new Date(b?.splitInvestment?.expirationDate) -
              new Date(a?.splitInvestment?.expirationDate)
            )
          })

          dispatch({ type: "SET_LISTINGS", payload: projects })
        } else {
          dispatch({ type: "SET_LISTINGS", payload: [] })
        }
      }

      fetchListings()
    }
  }, [])

  const SkeletonLoader = ({ length }) => {
    return Array.from({ length: length }).map((_, i) => (
      <div
        className="col-lg-3 col-12 col-sm-6 col-md-4 mt-4 mt-md-2 p-3 p-md-2 pb-0 pt-0 p-lg-3 skeleton"
        key={i}
      >
        <div className="card">
          <div className="card-body">
            <div className="card-title">
              <div className="skeleton-image full"></div>
              <div className="skeleton-line middle  mt-4"></div>
              <div className="skeleton-line full  mt-2"></div>
              <div className="d-flex justify-content-between">
                <div className="skeleton-line middle"></div>
                <div className="skeleton-line short"></div>
              </div>
            </div>
            <hr className="m-0 p-0 mb-3" />
            <div className="card-title">
              <div className="d-flex justify-content-between">
                <div className="skeleton-line  full"></div>
                <div className="skeleton-line full ms-3 me-3"></div>
                <div className="skeleton-line full"></div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="skeleton-line  full"></div>
                <div className="skeleton-line full ms-3 me-3"></div>
                <div className="skeleton-line full"></div>
              </div>
              <div className="skeleton-button full"></div>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  const activeListings = listings?.filter(
    item => item?.status === "bidding"
  ).length

  return (
    <Wrapper className="ps-4 pe-4">
      <div className="container">
        <div className="d-flex justify-content-between">
          {activeListings === 1 ? (
            <h3>
              {activeListings} {translate("homepage_listings_recent_single")}
            </h3>
          ) : (
            <h3>
              {activeListings} {translate("homepage_listings_recent")}
            </h3>
          )}
          <Link
            href="/listings"
            className="d-none d-md-block"
            onClick={() =>
              TagManager.dataLayer({
                dataLayer: {
                  event: "homepage_listings_button",
                  user_id: session?.uid,
                },
              })
            }
          >
            <Button variant="outlined" className="rounded-pill">
              {translate("homepage_listings_showMore")}
            </Button>
          </Link>
        </div>
        <div className="row d-flex d-xl-none">
          {listings?.length > 0 ? (
            listings.slice(0, 3)?.map((listing, i) => (
              <Fade
                in={fadeIn}
                timeout={{ enter: 500, exit: 250 }}
                style={{ transitionDelay: `${i * 50}ms` }}
                key={`asi-${i}`}
              >
                <div
                  className=" col-md-6 col-lg-4 col-xl-3 col-12 col-md-4 mt-4 p-4 p-md-2 pb-0 pt-0 p-lg-3 listing"
                  key={i}
                >
                  <Item listing={listing} session={session} />
                </div>
              </Fade>
            ))
          ) : (
            <SkeletonLoader length={3} />
          )}
        </div>
        <div className="row d-none d-xl-flex">
          {listings?.length > 0 ? (
            listings.slice(0, 4)?.map((listing, i) => (
              <Fade
                in={true}
                timeout={{ enter: 500, exit: 250 }}
                style={{ transitionDelay: `${i * 50}ms` }}
                key={`asi-${i}`}
              >
                <div
                  className=" col-md-6 col-lg-4 col-xl-3 col-12 col-md-4 mt-4 p-4 p-md-2 pb-0 pt-0 p-lg-3 listing"
                  key={i}
                >
                  <Item listing={listing} session={session} />
                </div>
              </Fade>
            ))
          ) : (
            <SkeletonLoader length={4} />
          )}
        </div>
        <div className="d-block d-md-none text-center mt-4">
          <Link href="/listings">
            <Button
              variant="outlined"
              className="rounded-pill"
              onClick={() =>
                TagManager.dataLayer({
                  dataLayer: {
                    event: "homepage_listings_button",
                    user_id: session?.uid,
                  },
                })
              }
            >
              {translate("homepage_listings_showMore")}
            </Button>
          </Link>
        </div>
      </div>
    </Wrapper>
  )
}

export default Highlights

const Wrapper = styled.div`
  padding: 2rem 0 3rem;

  @media (max-width: 768px) {
    .listing {
      margin: 0 auto;
    }
  }

  hr {
    color: #c4bfbf;
  }

  .card-body {
    padding: 1rem 1rem 0.5rem;
  }

  .skeleton-image {
    height: 200px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .skeleton-line {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .skeleton-button {
    margin-top: 15px;
    height: 35px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 0;
  }

  .full {
    width: 100%;
  }

  .long {
    width: 80%;
  }

  .short {
    width: 30%;
  }

  .middle {
    width: 50%;
  }

  @media (max-width: 800px) {
    .skeleton {
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
