import React, { useEffect, useState } from "react"
import Questions from "./Questions"
import { ArrowRight, ChevronRight } from "react-bootstrap-icons"
import { useRouter } from "next-translate-routes"
import translate from "@/utils/translation"
import { useSession } from "next-auth/react"
import QueryString from "qs"
import TagManager from "react-gtm-module"

const QuestionWrapper = () => {
  const [faqs, setFaqs] = useState([])

  const { data: session } = useSession()
  const router = useRouter()

  useEffect(() => {
    //fetch faqs

    const params = QueryString.stringify({
      populate: {
        localizations: "*",
      },
      fields: ["title", "body", "type", "locale"],
      pagination: {
        pageSize: 10,
      },
      filters: {
        homepage: true,
      },
    })

    const fetchFaqs = async () => {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/faqs?${params}`
      )
      const data = await res.json()

      // Sort FAQs based on the desired order
      const desiredOrder = [51, 54, 40, 105, 52, 102]

      // Sort FAQs based on the desired order
      const sortedFaqs = data.sort((a, b) => {
        const indexA = desiredOrder.indexOf(a.id)
        const indexB = desiredOrder.indexOf(b.id)

        // Handle cases where the id is not in desiredOrder
        if (indexA === -1) return 1
        if (indexB === -1) return -1

        return indexA - indexB
      })

      setFaqs(sortedFaqs)
    }

    fetchFaqs()
  }, [])

  return (
    <div>
      <h3>{translate("footer_business_faq")}</h3>
      <Questions faqs={faqs} />
      <div
        onClick={() => {
          router.push("/helpcenter"),
            TagManager.dataLayer({
              dataLayer: {
                event: "homepage_faq_button_all",
                user_id: session?.uid,
              },
            })
        }}
        className="more text-orange"
      >
        {translate("FAQ_show_more") + " "} <ArrowRight />
      </div>
    </div>
  )
}

export default QuestionWrapper
