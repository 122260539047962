import React from "react"
import styled from "styled-components"
import Infobox from "./infoBox"
import Infobox2 from "./infoBox2"

const Images = () => {
  return (
    <Wrapper>
      <div className="middle">
        <img
          src="./pages/homepage/banner/1.png"
          alt="banner"
          className="image1"
        />
        <img
          src="./pages/homepage/banner/2.png"
          alt="banner"
          className="image2"
        />
        <img
          src="./pages/homepage/banner/3.png"
          alt="banner"
          className="image3"
        />
        <img
          src="./pages/homepage/banner/4.png"
          alt="banner"
          className="image4"
        />
        <img
          src="./pages/homepage/banner/5.png"
          alt="banner"
          className="image5"
        />
        <img
          src="./pages/homepage/banner/6.png"
          alt="banner"
          className="image6"
        />
        <img
          src="./pages/homepage/banner/7.png"
          alt="banner"
          className="image7"
        />
        <img
          src="./pages/homepage/banner/8.png"
          alt="banner"
          className="image8"
        />
      </div>
      <div className="right">
        <img src="./pages/homepage/elements/performance.svg" alt="banner" />
      </div>
      <div className="left">
        <img src="./pages/homepage/elements/listing.svg" alt="banner" />
      </div>
      <div className="box-1">
        <Infobox />
      </div>
      <div className="box-2">
        <Infobox2 />
      </div>
      {/* <Infobox /> */}
    </Wrapper>
  )
}

export default Images

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    min-height: 420px;
  }

  .box-1 {
    position: absolute;
    z-index: 4;
    top: 25%;
    left: 5%;
    scale: 1;
    transform: translate(-50%, -50%);
    animation: slideInHoverFadeOut 20s ease-in-out infinite; /* Total duration for the complete effect */
    img {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
      top: 24%;
      left: -11%;
      transform: scale(0.8) !important;
    }
  }

  .box-2 {
    position: absolute;
    z-index: 4;
    top: 45%;
    left: 10%;
    scale: 1;
    transform: translate(-50%, -50%);
    animation: slideInHoverFadeOut 20s ease-in-out infinite; /* Total duration for the complete effect */
    img {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
      top: 43%;
      left: -5%;
      transform: scale(0.8) !important;
    }
  }

  .middle {
    z-index: 1;
    position: absolute;
    top: 10%;
    left: 25%;
    transform: translate(-50%, -50%);

    @media (max-width: 1200px) {
      top: 0;
      left: 20%;
    }

    @media (max-width: 768px) {
      top: 0%;
      left: 9%;
    }

    img {
      image-rendering: crisp-edges;
      z-index: 3;
      width: 300px;
      height: auto;
      position: absolute;
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
      animation: slideImages 40s infinite;
    }

    /* Stagger animation delay for each image */
    .image1 {
      animation-delay: 0s;
    }

    .image2 {
      animation-delay: 5s;
    }

    .image3 {
      animation-delay: 10s;
    }

    .image4 {
      animation-delay: 15s;
    }

    .image5 {
      animation-delay: 20s;
    }

    .image6 {
      animation-delay: 25s;
    }

    .image7 {
      animation-delay: 30s;
    }

    .image8 {
      animation-delay: 35s;
    }

    @keyframes slideImages {
      0% {
        opacity: 0;
      }
      2%,
      14.28% {
        opacity: 1;
      }
      16.28%,
      100% {
        opacity: 0;
      }
    }
  }

  /* Animation for the .left element */
  .left {
    z-index: 2;
    position: absolute;
    image-rendering: crisp-edges;
    top: 67%;
    left: 53%;
    z-index: 1;
    animation: slideInHoverFadeOutThird 20s ease-in-out infinite; /* Total duration for the complete effect */

    img {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 100px;
    }

    @media (max-width: 768px) {
      top: 70%;
      left: 40%;
    }
  }

  /* Animation for the .right element */
  .right {
    z-index: 2;
    position: absolute;
    image-rendering: crisp-edges;
    top: 250px;
    right: 13%;
    z-index: 1;
    animation: slideInHoverFadeOutSecond 20s ease-in-out infinite; /* Total duration for the complete effect */

    img {
      width: 180px;
    }

    @media (max-width: 768px) {
      top: 50%;
      left: 60%;

      img {
        width: 140px;
      }
    }
  }

  /* Keyframes for sliding in, hovering around, and fading out */
  @keyframes slideInHoverFadeOut {
    0% {
      transform: translateY(10vh); /* Start off-screen below */
      opacity: 0;
    }
    10% {
      transform: translateY(0); /* Slide in to final position */
      opacity: 1;
    }

    40% {
      transform: translateY(0); /* Slide in to final position */
      opacity: 1;
    }
    50% {
      transform: translateY(20vh); /* Slide down slightly while fading out */
      opacity: 0;
    }

    100% {
      transform: translateY(20vh); /* Slide down slightly while fading out */
      opacity: 0;
    }
  }

  @keyframes slideInHoverFadeOutSecond {
    0%,
    40% {
      opacity: 0;
    }
    45% {
      opacity: 0;
      transform: translate(0, -20px);
    }

    50% {
      opacity: 1;
      transform: translate(0, 0px);
    }

    95% {
      transform: translate(0px, 0px); /* Hovering */
      opacity: 1;
    }
    100% {
      transform: translateX(20px); /* Slide down slightly while fading out */
      opacity: 0;
    }
  }

  @keyframes slideInHoverFadeOutThird {
    0%,
    50% {
      opacity: 0;
      transform: translate(0, -20px);
    }

    55% {
      opacity: 1;
      transform: translate(0, 0px);
    }

    90% {
      transform: translate(0px, 0px); /* Hovering */
      opacity: 1;
    }
    95% {
      transform: translateX(20px); /* Slide down slightly while fading out */
      opacity: 0;
    }

    100% {
      transform: translateX(20px); /* Slide down slightly while fading out */
      opacity: 0;
    }
  }
`
