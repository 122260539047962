import { useSession } from "next-auth/react"
import { useRouter } from "next-translate-routes"
import React, { useEffect } from "react"
import styled from "styled-components"

const AFMBanner = () => {
  const { data: session } = useSession()
  const router = useRouter()

  const [isNL, setIsNL] = React.useState(false)

  useEffect(() => {
    if (
      router.locale?.toLowerCase() === "nl" ||
      session?.profile?.locale?.toLowerCase() === "nl" ||
      session?.profile?.address?.country?.toLowerCase() === "nl" ||
      navigator.language?.toLowerCase() === "nl" ||
      navigator.language?.toLowerCase() === "nl-NL"
    ) {
      setIsNL(true)
    }
  }, [])

  return (
    <Wrapper className=" w-100">
      {isNL ? (
        <>
          <div className="text-center">
            <img src="/components/AFM/AFM-Banner.png" className="w-100" />
          </div>
        </>
      ) : null}
    </Wrapper>
  )
}

export default AFMBanner

const Wrapper = styled.div`
  .small {
    font-size: 11px;
    color: #666;
    margin-bottom: 0.5rem;
  }
`
