import { Button, Chip } from "@mui/material"
import { useRouter } from "next/router"
import React from "react"
import { ArrowRight, CheckCircleFill } from "react-bootstrap-icons"
import styled from "styled-components"
import buyImageFirst from "@/public/pages/homepage/buy-house-valencia.jpg"
import buyImageSecond from "@/public/pages/homepage/Summarybox-Single-Listing.png"
import buyImageThird from "@/public/pages/homepage/moon.svg"
import buyImageFourth from "@/public/pages/homepage/line.svg"
import buyImageFifth from "@/public/pages/homepage/ellipse.svg"
import Image from "next/image"
import translate from "@/utils/translation"
import TagManager from "react-gtm-module"
import { useSession } from "next-auth/react"

const Buy = () => {
  const router = useRouter()
  const { data: session } = useSession()

  return (
    <Wrapper>
      <div className="container">
        <div className="row ps-4 pe-4 p-md-0 mt-5">
          <div className="textarea order-2 order-md-1 col-lg-6 mt-md-auto mb-md-auto textarea">
            <div className="inner">
              <h2>
                {translate("homepage_buy_title")}
                <span className="text-orange">
                  {" "}
                  {translate("homepage_buy_title_highlight")}
                </span>
              </h2>
              <p className="mt-3 mb-4">
                {translate("homepage_buy_description")}
              </p>
              <ul>
                <li>
                  <CheckCircleFill className="me-3" color="#00a1d1" size={16} />
                  {translate("homepage_buy_usp1")}
                </li>
                <li>
                  <CheckCircleFill className="me-3" color="#00a1d1" size={16} />
                  {translate("homepage_buy_usp2")}
                </li>
                <li>
                  <CheckCircleFill className="me-3" color="#00a1d1" size={16} />
                  {translate("homepage_buy_usp3")}
                </li>
              </ul>
              <div className=" mt-3">
                <Button
                  variant="contained"
                  size={"large"}
                  className="rounded-pill"
                  onClick={() => {
                    session?.profile
                      ? router.push(`/listings/`)
                      : router.push("/auth/register")
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "homepage_usp_button_2",
                        user_id: session?.uid,
                      },
                    })
                  }}
                >
                  {translate("homepage_buy_button")}
                  <ArrowRight className="ms-1" />
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 order-1 order-md-2 col-lg-6 pt-3 pt-5 pt-md-0">
            <div className="images">
              <Image
                width="300"
                height="300"
                src="/pages/homepage/Jordan-happy.jpg"
                alt="People in the Spanish office"
                className="first"
              />
              <Image
                src="/pages/homepage/pepe-pipes-2.jpg"
                width="300"
                height="300"
                className="second"
                alt="Best real estate in valencia"
              />
              <Image
                src={buyImageThird.src}
                width="50"
                height="50"
                className="third"
                alt="lines"
              />
              <Image
                src={buyImageFourth.src}
                width="100"
                height="100"
                className="fourth"
                alt="quarter moon"
              />
              <Image
                src={buyImageFifth.src}
                width="700"
                height="700"
                className="fifth"
                alt="ellipse"
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Buy

const Wrapper = styled.div`
  padding: 4rem 0;
  width: 100%;
  height: 100%;
  background-color: white;

  h2 {
    font-size: 1.8rem;
  }

  .images {
    position: relative;

    .first {
      position: absolute;
      top: -40px;
      z-index: 10;
      left: 10px;
      border-radius: 10px;
    }

    .second {
      position: absolute;
      top: 180px;
      right: 80px;
      z-index: 9;
      border-radius: 10px;
    }

    .third {
      position: absolute;
      top: 120px;
      right: 265px;
      z-index: 1;

      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
    }

    .fourth {
      position: absolute;
      top: 230px;
      left: 180px;
      z-index: 1;

      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
    }

    .fifth {
      position: absolute;
      top: -200px;
      right: 0px;
      z-index: 1;
    }
  }

  @media (max-width: 1400px) {
    .images {
      .first {
        position: absolute;
        top: 0px;
        left: 40px;
      }

      .second {
        top: 125px;
        right: 50px;
      }

      .third {
        top: 65px;
        right: 120px;
      }

      .fourth {
        top: 300px;
        left: 180px;
      }

      .fifth {
        top: -200px;
        right: 0px;
      }
    }
  }

  @media (max-width: 1200px) {
    .images {
      .first {
        position: absolute;
        top: -30px;
        left: 20px;
      }

      .second {
        top: 175px;
        right: 20px;
      }

      .third {
        top: 115px;
        right: 50px;
      }

      .fourth {
        top: 280px;
        left: 130px;
      }

      .fifth {
        top: -200px;
        right: 0px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    .images {
      left: 20px;

      .first {
        top: 0px;
        left: 120px;
        width: 200px;
        height: 200px;
      }

      .second {
        top: 125px;
        left: 50px;
        width: 146px;
        height: 180px;
      }

      .third {
        top: 70px;
        left: 60px;

        -moz-transform: scaleX(1);
        -o-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }

      .fourth {
        top: 200px;
        left: 200px;

        -moz-transform: scaleX(1);
        -o-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }

      .fifth {
        display: none;
      }

      height: 350px;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    .images {
      left: 0px;

      .first {
        top: 0px;
        left: 120px;
        width: 170px;
        height: 170px;
      }

      .second {
        top: 125px;
        left: 20px;
        width: 146px;
        height: 180px;
      }

      .third {
        top: 70px;
        left: 60px;

        -moz-transform: scaleX(1);
        -o-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }

      .fourth {
        top: 140px;
        left: 140px;

        -moz-transform: scaleX(1);
        -o-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }

      .fifth {
        display: none;
      }

      height: 350px;
    }
  }

  .usp {
    margin: auto 0;
    p {
      font-size: 1rem;
    }
  }

  .textarea {
    display: flex;

    .inner {
      background: white;
      position: relative;
      padding: 4rem 0rem 2rem;
      border-radius: 10px;

      z-index: 999;
      max-width: 550px;

      ul {
        padding-bottom: 1rem;
        padding-inline-start: 0px;
        list-style-type: none;

        li {
          margin-top: 1rem;
        }

        svg {
          margin-top: -3px;
        }
      }
    }
  }

  .route img {
    margin-left: -9rem;
  }
`
