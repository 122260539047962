import Image from "next/image"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"
import { ArrowRight } from "react-bootstrap-icons"
import background from "@/public/header-background.svg"
import Animation from "@/public/pages/homepage/lottie/34.json"
import Appointment from "../../listings/single/Appointment/Appointment"
import useComponentVisible from "@/components/common/useComponentVisible"
import Lottie from "react-lottie"
import { Button, TextField } from "@mui/material"
import { toast } from "react-toastify"
import translate from "@/utils/translation"
import axios from "axios"
import { Link } from "next-translate-routes"
import Reviews from "./reviews/reviews"
import Images from "./images/images"

const Homebanner = () => {
  const router = useRouter()

  const [email, setEmail] = React.useState("")
  const [errors, setErrors] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const { isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const translation = {
    email_error: translate("login_label_error_email"),
  }

  const confirmRegister = async () => {
    setLoading(true)
    setErrors()
    // Add email validation logic here
    // For example, you can use a regular expression to check if the email is in the correct format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      // Email is not in the correct format
      toast.error({ email: translation.email_error })
      setErrors({ email: translation.email_error })
      return
    }

    const exists = await axios.get(`/api/users?email=${email}`)

    if (exists.data.exists) {
      toast.error("Email already exists")
      setErrors({ email: "Email already exists" })
      setLoading(false)
    } else {
      // Email is correct, continue with the logic
      router.push("/auth/register?email=" + email)
      setLoading(false)
    }
  }

  return (
    <Wrapper className="css-16h2cue">
      <div className="container">
        <Appointment
          isComponentVisible={isComponentVisible}
          setIsComponentVisible={setIsComponentVisible}
        />
        <div className="row m-0">
          <div className="col-md-6 col-12 textWrapper">
            <h4 className="topheader mb-0">
              {translate("homepage_header_subtitle")}
            </h4>
            <h1 className="mt-md-3">
              {translate("homepage_header_title")}{" "}
              {translate("homepage_header_title_highlight")}
            </h1>
            <p className="mt-4 subtitle">
              {translate("homepage_header_description") + " "}
              <strong>{translate("homepage_header_description_2")}</strong>
              {" " + translate("homepage_header_description_3")}
            </p>
            <div className="mt-4 sign-up d-md-flex justify-content-center  justify-content-md-start">
              <div className="w-100 d-block">
                <TextField
                  placeholder="Your email"
                  minLength={3}
                  className="input w-100"
                  onChange={e => setEmail(e.target.value)}
                  error={errors?.email}
                  helperText={errors?.email}
                  autoComplete="email"
                />
              </div>
              <div className="ms-md-3 mt-2 mt-md-0 w-100 d-block">
                <Button
                  variant="contained"
                  className="header-button w-100 rounded-pill"
                  onClick={confirmRegister}
                >
                  {translate("homepage_header_primary_button")}
                  <ArrowRight className="ms-1" />
                </Button>
              </div>
              {/* <div className="ms-md-3 mt-2 mt-md-0 w-100 d-block">
                <Button
                  variant="outlined"
                  className="header-button border-0 w-100 rounded-pill"
                >
                  Bekijk eerdere projecten
                </Button>
              </div> */}
            </div>
            {/* <div className="mt-4 d-flex trustpilot d-block d-md-none">
              {/* <Image
                  src="/trustpilot.svg"
                  alt="trustpilot score"
                  width={110}
                  height={30}
                />
                <div className="mt-1 ms-3 stars">
                  <StarFill className="ms-1" color="#FFB800" size={16} />
                  <StarFill className="ms-1" color="#FFB800" size={16} />
                  <StarFill className="ms-1" color="#FFB800" size={16} />
                  <StarFill className="ms-1" color="#FFB800" size={16} />
                  <StarHalf className="ms-1" color="#FFB800" size={16} />
                </div>
                <div>
                  <p className="ms-3 text-grey" style={{ marginTop: "6px" }}>
                    {translate("homepage_header_trustpilot")}
                  </p>
                </div> */}
            <Reviews />
            <div className="mt-4 prospectus">
              <p>{translate("homepage_header_prospectus")}</p>
            </div>
          </div>
          <div className="col-md-6  col-12 position-relative">
            <Images />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Homebanner

const Wrapper = styled.div`
  width: 100%;
  min-height: 620px;
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;


  .showcase {
    color: #FB8500;
    text-decoration: none;
    font-size: 0.875rem;  
  }

  .sign-up {
    .MuiInputBase-root {
      max-height: 45px;
      height:45px;
      border-radius:20px;
      width:240px;
      background:white;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .header-button {
      max-height: 45px;
      height:45px;

      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }

    max-width:450px;
  }


    h1 {
      font-size: 2.7rem;
      line-height: normal;
      max-width: 600px;
      font-weight: 700;
    }
  }

  ul {
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 0;
    list-style: none;
    margin-left: -1.5rem;
    font-size: 1rem;
    font-weight: 300;

    li {
      margin-bottom: 0.4rem;
      color: black;
    }
  }

  .prospectus p{
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight:300;
    color: #585858;
    margin-bottom:0;
    text-decoration: underline;

  }

  .highlight {
    color: #fb8500;
    font-weight: 500;
    opacity: 1;
  }

  :before {
    content: "";
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    z-index: 1;

    background-image: url(${background.src});
    background-repeat: no-repeat;
  }

  .sell-button {
    margin-left: 1rem;
    border: 1px solid black;
    color: black;

    :hover {
      background-color: black;
      color: white;
      border: 1px solid black;
    }
  }

  .trustpilot p{
    text-decoration:none;
    -webkit-text-decoration:none;
    color:#17233b;
  }

  .textWrapper {
    margin-top: 4rem;
    margin-bottom:2rem;
    z-index:999;
  }

  .subtitle {
    max-width: 500px;
    font-size: 1.1rem;
    font-weight:300;
    padding: 0 0 0.5rem;
    line-height: 1.6rem;
  }

  .topheader {
    font-size: 0.875rem;
    color: #fb8500;
    font-weight: 400;
    margin-bottom: 1.125rem;
  }


  .animation {

    margin-top: -3.5rem;
    position: relative;
    z-index:1;
    


    -webkit-font-smoothing: subpixel-antialiased;

    div {
      cursor: default;
      position: absolute;
      width: 700px !important;
      height: 700px !important;

      @media (max-width: 1200px) {
        width: 475px !important;
        height: 475px !important;
        margin-top: 1rem !important;
      }

      @media (max-width: 992px) {
        width: 400px !important;
        height: 400px !important;
        margin-top: 3rem !important;
      }
    }

    svg {
      transform: unset !important;
      -webkit-font-smoothing: subpixel-antialiased;
    }
  }

  @media (max-width: 1200px) {
    .animation {
      margin-top: 2rem;
    }
  }

  @media (max-width: 992px) {
    .animation {
      margin-top: 0;
    }

    h1 {
      font-size: 2.2rem;
    }
  }

  @media (max-width: 768px) {
    .trustpilot {
      justify-content: center;
    }

    min-height: 400px;

    padding-top: 0rem;


    .stars {
      margin-left: 0.6rem !important;
    }

    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
      font-weight:700;
      
    }

    .textWrapper {
      margin-top: 3rem;


    }

    .subtitle {
      font-size: 1.1rem;
    font-weight:300;
    line-height: 1.6rem;
    }

    .buy-button {
      width: 100%;
      display: block;
      padding: 0rem;
    }

    .sell-button {
      width: 100%;
      display: block;
      margin-left: 0;
      margin-top: 1rem;
      background-color: white;
      padding: 1rem;
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 2.6rem;
      line-height: 3.2rem;
      font-weight:700;
    }

    .textWrapper {
      margin-top: 1rem;


    }
  }

  @media (max-width: 400px) {

    h1 {
        font-size: 1.75rem;
        line-height: 2.5rem;
    }

    .subtitle{
        font-size: 1.1rem;
    font-weight:300;
      margin-top:0.75rem !important;
    }


    margin-bottom;3rem;

  }
`
