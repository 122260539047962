import React, { useContext, useEffect, useRef } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import axios from "axios" // Added axios import
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Item from "@/components/listings/overview/listingOverview/item/item"
import { Context } from "@/context/state"
import translate from "@/utils/translation"

const DealSlider = ({ session }) => {
  const { state, dispatch } = useContext(Context)

  const listings = state?.listings ?? []

  const slider = useRef(null)

  const activeListings = listings?.filter(
    item => item.status === "bidding"
  ).length

  useEffect(() => {
    if (listings.length === 0) {
      const fetchListings = async () => {
        dispatch({ type: "SET_LOADING", payload: true })

        try {
          const result = await axios.get("/api/listings?split=true&limit=4")
          if (result?.data?.length > 0) {
            const projects = result.data.sort((a, b) => {
              return (
                new Date(b?.splitInvestment?.expirationDate) -
                new Date(a?.splitInvestment?.expirationDate)
              )
            })
            dispatch({ type: "SET_LISTINGS", payload: projects })
          } else {
            dispatch({ type: "SET_LISTINGS", payload: [] })
          }
        } catch (err) {
          console.log(err)
          dispatch({ type: "SET_LISTINGS", payload: [] })
        } finally {
          dispatch({ type: "SET_LOADING", payload: false })
        }
      }

      fetchListings()
    }
  }, [listings, dispatch])

  const settings = {
    className: "center",
    centerPadding: "60px",
    slidesToShow: 2,
    swipeToSlide: false,
    autoplay: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1.2,
          centerPadding: "150px",
        },
      },
    ],
  }

  if (!listings) return null

  const limitedListings = listings.slice(0, 6)

  return (
    <>
      <App>
        <div className="container">
          <div className="d-flex justify-content-between row m-0">
            <div className="col-12">
              {activeListings === 1 ? (
                <h2>
                  {activeListings}{" "}
                  {translate("homepage_listings_recent_single")}
                </h2>
              ) : (
                <h2>
                  {activeListings} {translate("homepage_listings_recent")}
                </h2>
              )}
            </div>
          </div>
        </div>
        <div>
          <Slider
            className="slide"
            ref={c => (slider.current = c)}
            {...settings}
          >
            {limitedListings.length > 0 ? (
              limitedListings.map(listing => (
                <div
                  className="col-md-6 ms-3 col-lg-4 col-xl-3 col-12 col-md-4 mt-0 p-1 p-md-2 pb-0 pt-0 p-lg-3 listing"
                  key={listing.uid}
                >
                  <Item listing={listing} session={session} />
                </div>
              ))
            ) : (
              <div className="col-12 d-flex justify-content-center">
                Loading...
              </div>
            )}
          </Slider>
        </div>
      </App>
    </>
  )
}

const App = styled.div`
  padding-bottom: 2rem;

  h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .slide {
    max-height: auto;
    margin-left: 0px; // Adjusted from -10px to 0px

    .listing {
      padding: 0rem 0rem 0rem 2rem !important;
    }

    .slick-track {
      left: 100px; // Adjusted from 85px to 100px for more spacing from the left

      @media (max-width: 768px) {
        left: 20px; // Moved the slider a bit on mobile too for consistency
      }
    }

    .item {
      padding: 10px;

      :first-child {
        padding-left: 0;
      }

      .inner {
        min-height: 330px;
        background: #fffbf8;
        border-radius: 10px;
        z-index: 1;
        border: 0.25px solid #fcc17f;
        padding: 1.5rem 2rem;

        .name {
          margin-left: 1rem;
        }

        .img img {
          border-radius: 50px;
          max-width: 40px;
          max-height: 40px;
          margin-top: 0.7rem;
        }
      }

      p {
        font-size: 0.875rem;
        margin-top: 1.25rem;
        color: #454f62;
        line-height: 1.7rem;
      }

      h5 {
        line-height: 1rem;
        font-size: 1.125rem;
        padding-top: 0.5rem;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
      }
    }
  }
`

export default DealSlider
