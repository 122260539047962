import { Context } from "@/context/state"
import translate from "@/utils/translation"
import { Button, CircularProgress } from "@mui/material"
import axios from "axios"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import DealSlider from "./Dealslider"
import Item from "@/components/listings/overview/listingOverview/item/item"
import TagManager from "react-gtm-module"
import { useSession } from "next-auth/react"

const ListingMobile = ({ session }) => {
  const router = useRouter()

  //generate state from context
  const { state, dispatch } = useContext(Context)

  var listings = [...(state?.listings ? state.listings : [])]

  useEffect(() => {
    if (listings?.length === 0) {
      const fetchListings = async () => {
        dispatch({ type: "SET_LOADING", payload: true })

        const result = await axios
          .get("/api/listings?split=true&limit=4")
          .catch(err => console.log(err))

        if (result?.data?.length > 0) {
          const projects = result.data.sort((a, b) => {
            return (
              new Date(b?.splitInvestment?.expirationDate) -
              new Date(a?.splitInvestment?.expirationDate)
            )
          })

          dispatch({ type: "SET_LISTINGS", payload: projects })
        } else {
          dispatch({ type: "SET_LISTINGS", payload: [] })
        }
      }

      fetchListings()
    }
  }, [])

  const slider = useRef()

  const activeListings = listings?.filter(
    item => item.status === "bidding"
  ).length

  return (
    <Wrapper className="" ref={slider}>
      <div className="container">
        <div className="d-flex justify-content-between row m-0">
          <div className="col-12">
            {activeListings === 1 ? (
              <h3>
                {activeListings} {translate("homepage_listings_recent_single")}
              </h3>
            ) : (
              <h3>
                {activeListings} {translate("homepage_listings_recent")}
              </h3>
            )}
            <Link href="/listings" className="d-none d-md-block">
              <Button variant="outlined" className="rounded-pill">
                {translate("homepage_listings_showMore")}
              </Button>
            </Link>
          </div>
          {listings.length > 0 ? (
            listings.map(listing => (
              <div className="mt-3 col-12 d-block d-lg-none" key={listing.uid}>
                <Item listing={listing} session={session} />
              </div>
            ))
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default ListingMobile

const Wrapper = styled.div`
  padding-bottom: 3rem;

  h2 {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    .listing {
      margin: 0 !important;
    }

    h3 {
      font-size: 1.5rem;
    }
  }
`
