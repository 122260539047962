import HeaderNavigation from "@/components/common/Layout/header/HeaderNavigation"
import Buy from "@/components/homepage/Buy"
import ListingMobile from "@/components/homepage/ListingsMobile/ListingMobile"
import BuyMobile from "@/components/homepage/BuyMobile/BuyMobile"
import Faq from "@/components/homepage/Faq/Faq"
import Highlights from "@/components/homepage/Listings"
import Homebanner from "@/components/homepage/banner/Homebanner"
import HomebannerTest from "@/components/homepage/banner/HomebannerTest"
import Video from "@/components/homepage/video/Video"
import Performance from "@/components/homepage/performance/performance"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import CTA from "@/components/homepage/CTA/CTA"
import DealSlider from "@/components/homepage/ListingsMobile/Dealslider"

const Home = ({ session }) => {
  const isFeatureOn = useFeatureIsOn("homepage-test")

  return (
    <>
      <HeaderNavigation />
      {!isFeatureOn ? <Homebanner /> : <HomebannerTest />}
      <Performance />
      {/* <PerformanceSlider totalInvestment={totalInvestment} /> */}
      <div className="d-none d-lg-block">
        <Highlights session={session} />
      </div>
      <div className="d-block d-lg-none">
        <DealSlider session={session} />
      </div>
      <Video />
      <div className="d-none d-md-block">
        <Buy />
      </div>
      <div className="d-md-none">
        <BuyMobile />
      </div>
      {/* <Review /> */}
      <Faq />
      <CTA />
    </>
  )
}

export default Home
